export const IsPhone = {
  reg: /^1[3456789]\d{9}$/,
};
export const IsFax = {
  reg: /^(\d{3,4}-)?\d{7,8}$/,
};
export const IsNotEmpty = {
  reg: /([^\s$])/,
};
export const IsImage = {
  reg: /^image\//,
};
export const IsVideo = {
  reg: /^video\//,
};
export const IsGuid = {
  reg: /^[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}$/,
};
export const IsIllegalChar = {
  reg: /^[a-zA-Z0-9\u4e00-\u9fa5\s]+$/,
};
export const IsVersionNumber = {
  reg: /^\d+\.\d+\.\d+$/,
};
export const isInteger = {
  reg: /^-?\d+$/,
};
export const isFloat = {
  reg: /^\d+\.\d+$/,
};
export const isEmail = {
  reg: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/,
};
export const isPassword = {
  reg: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,16}$/,
};
export const inputLimitedLength = {
  name: 30,
  description: 200,
};

export const localhostReg = {
  reg: /^(\[::1\])|(localhost)|(127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3})$/,
};
