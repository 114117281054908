/** @jsx jsx */
import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import { Button as AntButton } from "antd";
import { ButtonProps as AntButtonProps } from "antd/lib/button/button";
import React, { useMemo } from "react";
import { useTheme } from "src/hooks";

interface ButtonProps extends AntButtonProps {
  className?: string;
}

export default React.memo(function Button(props: ButtonProps) {
  const { className, type, children, size, ...restProps } = props;
  const theme = useTheme();

  const sizeStyle = useMemo(() => {
    switch (size) {
      case "small":
        return css`
          padding: 4px 15px;
          font-size: 12px;
        `;
      default:
        return css`
          padding: 4px 20px;
          font-size: 14px;
        `;
    }
  }, [size]);

  const typeStyle = useMemo(() => {
    const defaultStyle = css`
      border: solid 1px;
      border-radius: 4px;
      height: auto;
      color: ${theme.buttonText};
      :hover {
        color: ${theme.primaryColor};
        border-color: ${theme.primaryColor};
      }
    `;
    switch (type) {
      case "primary":
        return css`
          ${defaultStyle}
          color: ${theme.primaryButtonText};
          background-color: ${theme.primaryButtonBackground};
          border-color: ${theme.primaryButtonBackground};
          :hover,
          :focus,
          :active {
            color: ${theme.primaryButtonText};
            background-color: ${theme.primaryButtonBackgroundHovered};
            border-color: ${theme.primaryButtonBackgroundHovered};
          }
        `;
      case "link":
        return css`
          padding: 0;
          border: none;
          color: ${theme.primaryColor};
          background: none;
          :hover {
            background: none;
            border: none;
            box-shadow: none;
          }
        `;
      case "dashed":
        return css`
          ${defaultStyle}
          border-style: dashed
        `;
      case "default":
        return defaultStyle;
    }
  }, [theme, type]);
  return (
    <AntButton
      css={css`
        ${sizeStyle}
        ${typeStyle}
      `}
      type={type}
      className={className}
      {...restProps}
    >
      {children}
    </AntButton>
  );
});
