/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useCallback, useEffect } from "react";
import { useGetContainer } from "src/hooks";
import { AppModel } from "src/store/models/app";
import { RouterModel } from "src/store/models/router";

export default React.memo(() => {
  const getContainer = useGetContainer();
  const app = getContainer(AppModel);
  const goPage = useCallback(
    (url: string) => {
      getContainer(RouterModel).actions.push.dispatch("/");
    },
    [getContainer]
  );
  useEffect(() => {
    app.actions.logout.dispatch({});
    goPage("");
  }, [app.actions.logout, goPage]);
  return <div></div>;
});
