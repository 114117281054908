import {
  AxiosHttpClient,
  HeaderMiddleware,
  HttpClient,
  Profile,
  RequestQueue,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import { EncooOidcClient } from "@encoo-web/oidc-client";
import { createBrowserHistory } from "history";
import { Container } from "inversify";
import Oidc from "oidc-client";
import { consts } from "src/consts";
import { getDependencies } from "src/dependencies";
import {
  CompanyHeaderMiddlewareSymbol,
  DepartmentHeaderMiddlewareSymbol,
  DependenciesSymbol,
  ErrorMiddlewareSymbol,
  HistorySymbol,
  HttpClientSymbol,
  ProfileSymbol,
  RequestQueueMiddlewareSymbol,
  ServiceCredentialsSymbol,
  TenantHeaderMiddlewareSymbol,
  UserManagerSettingsSymbol,
} from "src/dependencies/identifiers";
import { OidcClientAuthenticationCredential } from "src/dependencies/oidcClient";
import { devProfile } from "src/dependencies/_settings";
import { AppsV2ServiceClient } from "src/services/appsV2Service";
import { CompanyServiceClient } from "src/services/Company";
import { CompanyUserServiceClient } from "src/services/CompanyUserService";
import { DepartmentServiceClient } from "src/services/DepartmentService";
import { LocaleService } from "src/services/LocaleService";
import { ReduxService } from "src/services/ReduxService";
import { ThemeService } from "src/services/ThemeService";
import { ErrorMiddleware } from "src/store/models/ui/error/error";
import { createInjectableFactory } from "src/utils/inversify";

declare global {
  interface Window {
    _settings: {
      endpoint: string;
      managementUrl: string;
      appsUrl: string;
      env: string;
      customizedCustomer?: "tss" | null;
      disableApplicationInsights?: false;
    };
  }
}

const container = new Container({
  defaultScope: "Singleton",
});

container.bind(DependenciesSymbol).toDynamicValue(() => {
  return getDependencies();
});
container.bind(HistorySymbol).toDynamicValue(() => {
  return createBrowserHistory();
});

container
  .bind(LocaleService)
  .toDynamicValue(createInjectableFactory(LocaleService));
container
  .bind(ReduxService)
  .toDynamicValue(createInjectableFactory(ReduxService));
container
  .bind(ThemeService)
  .toDynamicValue(createInjectableFactory(ThemeService));

container
  .bind(TenantHeaderMiddlewareSymbol)
  .toDynamicValue(() => new HeaderMiddleware("TenantId", ""));

container
  .bind(CompanyHeaderMiddlewareSymbol)
  .toDynamicValue(() => new HeaderMiddleware("CompanyId", ""));

container
  .bind(DepartmentHeaderMiddlewareSymbol)
  .toDynamicValue(() => new HeaderMiddleware("DepartmentId", ""));

container
  .bind(RequestQueueMiddlewareSymbol)
  .toDynamicValue(() => new RequestQueue());
container
  .bind(ErrorMiddlewareSymbol)
  .toDynamicValue(() => new ErrorMiddleware());

container.bind(HttpClientSymbol).toDynamicValue((context) => {
  const client = new AxiosHttpClient();
  client.use(context.container.get(ErrorMiddlewareSymbol));
  client.use(context.container.get(TenantHeaderMiddlewareSymbol));
  client.use(context.container.get(CompanyHeaderMiddlewareSymbol));
  client.use(context.container.get(DepartmentHeaderMiddlewareSymbol));
  client.use(context.container.get(RequestQueueMiddlewareSymbol));
  return client;
});

if (
  window._settings &&
  window._settings.endpoint &&
  window._settings.managementUrl
) {
  container.bind(ProfileSymbol).toDynamicValue(() => window._settings);
} else {
  container.bind(ProfileSymbol).toDynamicValue(() => devProfile);
}

const hostPrefix = `${window.location.protocol}//${window.location.host}`;

container.bind(UserManagerSettingsSymbol).toDynamicValue((context) => {
  return {
    authority: context.container.get<Profile>(ProfileSymbol).managementUrl,
    /* eslint-disable */
    client_id: consts.settings.OidcClientId,
    redirect_uri: `${hostPrefix}/${consts.settings.OidcCallbackUriAfterfix}`,
    silent_redirect_uri: `${hostPrefix}/${consts.settings.OidcSilentRenewAfterfix}`,
    post_logout_redirect_uri: `${hostPrefix}/${consts.settings.OidcLogoutUriAfterfix}`,
    response_type: "code",
    /* eslint-enable */
    scope: consts.settings.OidcScope,
    accessTokenExpiringNotificationTime: 4,
    automaticSilentRenew: false,
    filterProtocolClaims: true,
    // eslint-disable-next-line @typescript-eslint/camelcase
    response_mode: "query",
    userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
  };
});

container
  .bind(ServiceCredentialsSymbol)
  .toDynamicValue(
    (context) =>
      new OidcClientAuthenticationCredential(
        context.container.get(UserManagerSettingsSymbol)
      )
  );

container
  .bind(EncooOidcClient)
  .toDynamicValue(
    (context) =>
      new EncooOidcClient(context.container.get(UserManagerSettingsSymbol))
  );

container
  .bind(CompanyServiceClient)
  .toDynamicValue(
    (context) =>
      new CompanyServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(DepartmentServiceClient)
  .toDynamicValue(
    (context) =>
      new DepartmentServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(AppsV2ServiceClient)
  .toDynamicValue(
    (context) =>
      new AppsV2ServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(CompanyUserServiceClient)
  .toDynamicValue(
    (context) =>
      new CompanyUserServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
export default container;
