export default {
  apps: {
    myApp: {
      card: {
        menu: {
          copyAppLink: "复制链接",
          linkShare: "链接分享",
          qrCodeShare: "二维码分享",
          emailShare: "邮件分享",
          copySuccess: "复制成功",
          copyAppProject: "复制",
          hasCopiedLink: "已复制链接",
          recevierEmail: "收件人邮箱",
          emailTitle: "邀请使用应用",
          emailContent:
            "您好\r\n \t邀请您点击链接，打开应用：{shareUrl} \r\n\r\n 谢谢",
        },
      },
      currentVersion:"当前版本",
      lastUpdate:"上次更新",
      share:"分享",
      useApp: "使用",
      appsNotFoundText: "暂无此应用",
      noAppsText: "您还没有添加任何应用",
      introduction: "我的应用主要用于业务人员查看并使用被授权的应用。",
      noPermissionText: "由于您还没开通权限，无法进行操作",
      notFoundText: "点击返回首页",
      searchBoxPlaceholder: "请输入应用名",
      favorite: "收藏",
      unfavorite: "取消收藏",
    }

  },
};
