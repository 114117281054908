export default {
  app: {
    name: "云扩",
  },
  edition: {
    enterprise: "企业版",
    community: "社区版",
  },
  validate: {
    phone: "请正确填写手机号",
    fax: "请正确填写传真号码",
    email: "请正确填写邮箱",
    notEmpty: "填写项不能为空",
    image: "填写项不能为空",
    video: "填写项不能为空",
    guid: "填写项不是Guid",
    password: "8-16个字符，至少包含大写、小写、数字和特殊字符中三项",
  },
  common: {
    loginSuccess: "登录成功",
    operateSuccess: "操作成功",
    loginOut: "退出",
    goBack: "返回",
    required: "填写项不能为空",
    emailValidation: "填写正确的邮件地址",
    selectAll: "全选",
    robot: "机器人",
    today: "今天",
    currentMonth: "当月",
    editor: "编辑器",
    copy: "复制",
    placeholder: "请输入",
    alertTitle: "提示",
    updataTime: "更新时间",
    unBind: "解绑",
    bound: "已绑定",
    nobound: "未绑定",
    unBound: "未解绑",
    packageName: "流程包名称",
    create: "新建",
    multiControl: "批量操作",
    tag: "标签",
    delete: "删除",
    remove: "移除",
    operation: "操作",
    upload: "上传",
    resourceMove: "资源组迁移",
    processDeployment: "流程部署",
    status: "状态",
    note: "备注",
    view: "查看",
    log: "日志",
    loading: "加载中...",
    phone: "电话",
    download: "下载",
    upgrade: "升级",
    cancel: "取消",
    edit: "编辑",
    complete: "完成",
    type: "类型",
    createTime: "创建时间",
    description: "备注",
    creationTime: "创建时间",
    createdBy: "创建人",
    close: "关闭",
    confirm: "确定",
    save: "保存",
    pleaseInput: "请输入",
    pleaseSelect: "请选择",
    robotName: "机器人名称",
    deleteConfirmation: "确定删除",
    contact: "联系{attrType}",
    name: "名称",
    baseConfig: "基本配置",
    bindRobot: "关联机器人",
    add: "新增",
    nextStep: "下一步",
    preStep: "上一步",
    createSuccess: "添加成功",
    uploadSuccess: "上传成功",
    config: " 配置信息",
    bindWorkflow: "关联流程部署",
    empty: "无",
    home: "首页",
    yes: "是",
    no: "否",
    title: "标题",
    content: "正文",
    uploadFile: "上传文件",
    info: {
      createEntity: "新建{entityTypeName}",
      editEntity: "修改{entityTypeName}",
      createSuccessfully: "创建 {entityTypeName} {name} 成功",
      createFailed: "创建 {entityTypeName} {name} 失败",

      deletionConfirmation: "确定删除 {entityTypeName} {name} ?",
      deletionFailed: "删除 {entityTypeName} {name} 失败",
      deleteSuccessfully: "删除 {entityTypeName} {name} 成功",
      updateSuccessfully: "更新 {entityTypeName} {name} 成功",
      updateFailed: "更新 {entityTypeName} {name} 失败",
      bindSuccessfully: "{name} 绑定 {entityTypeName}  成功",
      unbindSuccessfully: "{name} 解绑 {entityTypeName}  成功",
      bindFailed: "{name} 绑定 {entityTypeName}  失败",
      unbindFailed: "{name} 解绑 {entityTypeName}  失败",
    },
    copySuccessfully: "复制成功",
    updateSuccessfully: "修改成功",
    updateFailed: "修改失败",
    pleaseSelectTime: "请选择时间",
    startTime: "开始时间",
    endTime: "结束时间",
    baseInfo: "基本信息",
    Titles: {
      apps: "我的小程序",
    },
  },
  week: {
    MON: "星期一",
    TUE: "星期二",
    WED: "星期三",
    THU: "星期四",
    FRI: "星期五",
    SAT: "星期六",
    SUN: "星期日",
  },
};
