import { goBack, push, replace, RouterState } from "connected-react-router";
import { LocationDescriptorObject, Path } from "history";
import { createModel, createSelector } from "nyax";
import { appRouteDefinitions, AppRouteInfo } from "src/routes";
import { ModelBase } from "src/store/ModelBase";
import { assert } from "src/utils/shared";

export const RouterModel = createModel(
  class extends ModelBase {
    public selectors() {
      return {
        router: (): RouterState =>
          this.dependencies.redux.store.getState().router,
        pathname: (): string => this.getters.router.location.pathname,
        searchParams: createSelector(
          (): string => this.getters.router.location.search,
          (search) => new URLSearchParams(search)
        ),
        currentRouteInfo: createSelector(
          (): string => this.getters.pathname,
          (): URLSearchParams => this.getters.searchParams,
          (pathname, searchParams): AppRouteInfo => {
            const routeDefinition = Object.values(appRouteDefinitions).find(
              (e) => {
                let regStr = `^${e.path}$`;
                Object.entries(e.defaultParams).forEach(([key, value]) => {
                  regStr = regStr.replace(`:${key}`, "[^/]*");
                });
                const reg = new RegExp(regStr);
                const flag = pathname
                  ? reg.test(pathname)
                  : e.path === pathname;
                return flag;
              }
            );

            if (!routeDefinition) {
              return {
                type: "404",
                params: {},
              };
            }

            const params: Record<string, string> = {};
            const urlFragments = pathname.split("/");
            const pathFragments = routeDefinition.path.split("/");

            Object.entries(routeDefinition.defaultParams).forEach(
              ([key, value]) => {
                if (routeDefinition.path.includes(`:${key}`)) {
                  const index = pathFragments.indexOf(`:${key}`);
                  params[key] = urlFragments[index];
                } else {
                  params[key] = searchParams.get(key) ?? value;
                }
              }
            );

            return {
              ...routeDefinition,
              params,
            } as AppRouteInfo;
          }
        ),
      };
    }

    public effects() {
      return {
        push: async (pathOrLocation: Path | LocationDescriptorObject) => {
          await this.dependencies.redux.store.dispatch(
            typeof pathOrLocation === "string"
              ? push(pathOrLocation)
              : push(pathOrLocation)
          );
        },
        goBack: async () => {
          await this.dependencies.redux.store.dispatch(goBack());
        },
        navigateByRouteInfo: async (routeInfo: AppRouteInfo) => {
          const { pathname, searchParams } = this.getRouteInfo(routeInfo);
          await this.dependencies.redux.store.dispatch(
            push({
              pathname,
              search: "" + searchParams,
            })
          );
        },
        replaceByRouteInfo: async (routeInfo: AppRouteInfo) => {
          const { pathname, searchParams } = this.getRouteInfo(routeInfo);
          await this.dependencies.redux.store.dispatch(
            replace({
              pathname,
              search: "" + searchParams,
            })
          );
        },
      };
    }

    private getRouteInfo(
      routeInfo: AppRouteInfo
    ): {
      pathname: string;
      searchParams: URLSearchParams;
    } {
      const routeDefinition = Object.values(appRouteDefinitions).find(
        (e) => e.type === routeInfo.type
      );
      assert(routeDefinition);

      let pathname = routeDefinition.path;
      const params: Record<string, string> = {
        ...routeDefinition.defaultParams,
        ...routeInfo.params,
      };

      Object.entries(params).forEach(([key, value]) => {
        if (routeDefinition.path.includes(`:${key}`)) {
          pathname = pathname.replace(`:${key}`, value);
          delete params[key];
        }
      });

      return {
        pathname,
        searchParams: new URLSearchParams(params),
      };
    }
  }
);
