/** @jsx jsx */
import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import React from "react";
import { useSelector } from "react-redux";
import MyApp from "src/containers/apps/MyApp";
import { ThemeContext } from "src/contexts";
import { useGetContainer, useThemeSet } from "src/hooks";
import { HeaderUIModel } from "src/store/models/ui/home/header";
import MyAppMobile from "../apps/MyAppMobile";

const BodyInner = React.memo(function BodyInner() {
  const getContainer = useGetContainer();
  const headerUIModel = getContainer(HeaderUIModel);

  const isMoiile = useSelector(
    () => window.navigator.userAgent.toLocaleLowerCase().indexOf("mobile") >= 0
  );
  const isInitialized = useSelector(() => headerUIModel.state.isInitialized);
  return isInitialized ? (
    isMoiile ? (
      <MyAppMobile></MyAppMobile>
    ) : (
      <MyApp></MyApp>
    )
  ) : (
    <span></span>
  );
});

export default React.memo(function Body(props: {}) {
  const themeSet = useThemeSet();
  return (
    <ThemeContext.Provider value={themeSet.body}>
      <main
        css={css`
          display: flex;
          flex-grow: 1;
          width: 100%;
          min-height: 200px;
          position: relative;
          background-color: #f8f8f8;
          overflow: auto;
        `}
      >
        <BodyInner></BodyInner>
      </main>
    </ThemeContext.Provider>
  );
});
