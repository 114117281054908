/** @jsx jsx */
import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import React, { Suspense, useMemo } from "react";
import { useSelector } from "react-redux";
import { consts } from "src/consts";
import Body from "src/containers/home/Body";
import Header from "src/containers/home/Header";
import LoadingContainer from "src/containers/loading/Loading";
import { useGetContainer } from "src/hooks";
import { RouterModel } from "src/store/models/router";

export default React.memo(function AppLayout() {
  const getContainer = useGetContainer();
  const router = getContainer(RouterModel);

  const currentRouteInfo = useSelector(() => router.getters.currentRouteInfo);

  const mainNode = useMemo(() => {
    if (currentRouteInfo.component) {
      const LoadComponent = React.lazy(
        () => import(`${currentRouteInfo.component}`)
      );
      return (
        <Suspense fallback={null}>
          <LoadComponent />
        </Suspense>
      );
    }
    return null;
  }, [currentRouteInfo.component]);

  if (currentRouteInfo.type === consts.settings.OidcCallbackUriAfterfix)
    return mainNode;
  return (
    <div
      css={css`
        display: flex;
        flex-grow: 1;
        min-width: 0;
        min-height: 0;
      `}
    >
      <LoadingContainer />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          min-width: 0;
          min-height: 0;
        `}
      >
        <Header></Header>
        <div
          css={css`
            display: flex;
            flex-grow: 1;
            min-width: 0;
            min-height: 0;
          `}
        >
          <Body></Body>
        </div>
      </div>
    </div>
  );
});
