/** @jsx jsx */
import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import { Spin } from "antd";
import React, { useEffect } from "react";
import { hot } from "react-hot-loader/root";
import { useSelector } from "react-redux";
import AppLayout from "src/containers/AppLayout";
import { useFormatMessage, useGetContainer } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { AppModel } from "src/store/models/app";

export default hot(
  React.memo(function App() {
    const formatMessage = useFormatMessage();
    const getContainer = useGetContainer();
    const app = getContainer(AppModel);

    const isInitialized = useSelector(() => app.state.isInitialized);

    useEffect(() => {
      document.title = formatMessage(messageIds.app.name);
    }, [formatMessage]);

    useEffect(() => {
      app.actions.initializeRequest.dispatch({});
    }, [app.actions.initializeRequest]);

    return isInitialized ? (
      <AppLayout />
    ) : (
      <Spin
        css={css`
          margin: auto;
        `}
      />
    );
  })
);
