/** @jsx jsx */
import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import { Dropdown, Menu, notification } from "antd";
import { memoize } from "lodash";
import React, { Fragment, useMemo } from "react";
import { useSelector } from "react-redux";
import moreIcon from "src/assets/more.png";
import { useFormatMessage, useGetContainer, useTheme } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { AppV2 } from "src/models/appV2";
import { AppModel } from "src/store/models/app";
import { copyText } from "src/utils/copyText";

export default React.memo(function AppExplorerCard(props: { app: AppV2 }) {
  const { app } = props;

  const theme = useTheme();
  const formatMessage = useFormatMessage();
  const getContainer = useGetContainer();

  const application = getContainer(AppModel);
  const selectCompanyId = useSelector(() => application.state.currentCompanyId);
  const selectDepaetmentId = useSelector(
    () => application.state.currentDepartmentId
  );

  const copyAppLink = useMemo(() => {
    return memoize(async () => {
      const url = `${window._settings.appsUrl}app?companyId=${selectCompanyId}&departmentId=${selectDepaetmentId}&appId=${app.id}&appVersionId=${app.lastVersionId}`;
      copyText(url);
      notification.success({
        message: formatMessage(messageIds.apps.myApp.card.menu.copySuccess),
      });
    });
  }, [
    app.id,
    app.lastVersionId,
    formatMessage,
    selectCompanyId,
    selectDepaetmentId,
  ]);

  const menus = useMemo(
    () => (
      <Menu>
        <Menu.Item key={""} onClick={copyAppLink}>
          {formatMessage(messageIds.apps.myApp.card.menu.copyAppLink)}
        </Menu.Item>
      </Menu>
    ),
    [copyAppLink, formatMessage]
  );

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 254px;
        height: 234px;
        border: 1px solid ${theme.bodyDivider};
        border-radius: 5px;
        padding: 20px;
        margin-right: 20px;
        margin-bottom: 20px;

        :hover {
          box-shadow: 0px 3px 10px 0px ${theme.bodyFrameShadowColor};

          .link-useApp {
            display: inline-block;
            background-color: #1890ff;
            color: #fff;
          }
        }
      `}
    >
      <Fragment>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            flex-shrink: 0;
            justify-content: center;
            height: 48px;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              position: relative;
              padding: 0;
            `}
          >
            <img
              css={css`
                width: 48px;
              `}
              src={app.icon || "./app_icons/default.png"}
              alt={app.name}
            />
            <div
              css={css`
                width: 150px;
              `}
            ></div>
            <Dropdown overlay={menus}>
              <img
                src={moreIcon}
                alt=""
                css={css`
                  width: 15px;
                  height: 15px;
                `}
              />
            </Dropdown>
          </div>
        </div>
        <div
          css={css`
            flex-shrink: 0;
            align-items: center;
            height: 100px;
          `}
        >
          <div
            css={css`
              font-size: 18px;
              color: #3e434d;
              margin-top: 20px;
              margin-bottom: 8px;
              height: 25px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            `}
          >
            {app.name}
          </div>
          <div
            css={css`
              font-size: 14px;
              color: #8f9bb3;
              margin-top: 10px;
              margin-bottom: 8px;
              height: 48px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            `}
          >
            {app.description}
          </div>
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            height: 80px;
            margin-top: 16px;
          `}
        >
          <a
            css={css`
              border: 1px solid #1890ff;
              width: 80px;
              height: 32px;
              text-align: center;
              font-size: 14px;
              font-family: sans-serif;
              letter-spacing: 8px;
              padding-top: 4px;
              padding-left: 6px;
              border-radius: 20px;
            `}
            className="link-useApp"
            href={`${window._settings.appsUrl}app?companyId=${selectCompanyId}&departmentId=${selectDepaetmentId}&appId=${app.id}&appVersionId=${app.lastVersionId}`}
          >
            {formatMessage(messageIds.apps.myApp.useApp)}
          </a>
        </div>
      </Fragment>
    </div>
  );
});
