import { createModel, createSelector } from "nyax";
import {
  Department,
  DepartmentTreeData,
  PermissionTargetDepartment,
} from "src/models/department";
import { ModelBase } from "src/store/ModelBase";
import { AppModel } from "src/store/models/app";
import { CompanyListModel } from "src/store/models/entity/company/list";
import { DepartmentHelperModel } from "src/store/models/entity/department/helper";

export const HeaderUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        departmentTreeData: null as DepartmentTreeData | null,
        isInitialized: false as boolean,
        departmentList: [] as Department[] | null,
        isShowSelect: false as boolean,
        currentSelectDepartment: [] as PermissionTargetDepartment[],
        selectDepartmentId: "" as string,
      };
    }

    public selectors() {
      return {
        getCurrentDepartmentName: createSelector(
          () => this.getContainer(AppModel).state.currentDepartmentId,
          () => this.state.departmentTreeData,
          (departmentId, departmentTree) => {
            if (!departmentTree) {
              return "";
            }
            let departments = [] as Department[];

            const pushDepartments = function (
              target: PermissionTargetDepartment
            ) {
              departments.push(target);
              if (target.children) {
                target.children.map((t) => pushDepartments(t));
              }
            };
            if (departmentTree?.rootDepartment) {
              departments = [] as Department[];
              pushDepartments(departmentTree?.rootDepartment);
            }
            for (const department of departments) {
              if (department.departmentId === departmentId) {
                return department.name;
              }
            }
            return "";
          }
        ),
      };
    }

    public reducers() {
      return {
        setDepartmentTreeData: (value: DepartmentTreeData | null) => {
          this.state.departmentTreeData = value;
        },
        setInitialized: (value: boolean) => {
          this.state.isInitialized = value;
        },
        setIsShowSelect: (value: boolean) => {
          this.state.isShowSelect = value;
        },
        setCurrentSelectDepartment: (value: PermissionTargetDepartment[]) => {
          this.state.currentSelectDepartment = value;
        },
        goBack: () => {
          const firstDepartment = this.state.currentSelectDepartment[0];
          const departmentPath = firstDepartment.departmentPath;
          const departmentIds = departmentPath?.split("/");
          if (departmentIds) {
            if (departmentIds.length <= 2) {
              const departments = [] as PermissionTargetDepartment[];
              if (this.state.departmentTreeData?.rootDepartment) {
                departments.push(this.state.departmentTreeData?.rootDepartment);
              }
              this.state.currentSelectDepartment = departments;
            } else {
              let departments = [] as PermissionTargetDepartment[];

              const pushDepartments = function (
                target: PermissionTargetDepartment
              ) {
                departments.push(target);
                if (target.children) {
                  target.children.map((t) => pushDepartments(t));
                }
              };
              if (this.state.departmentTreeData?.rootDepartment) {
                departments = [] as Department[];
                pushDepartments(this.state.departmentTreeData?.rootDepartment);
              }
              const targetDepartmentPath = departmentPath?.replace(
                "/" +
                  departmentIds[departmentIds.length - 2] +
                  "/" +
                  departmentIds[departmentIds.length - 1],
                ""
              );

              departments.forEach((department) => {
                if (
                  department.departmentPath === targetDepartmentPath &&
                  department.children
                ) {
                  this.state.currentSelectDepartment = department.children;
                }
              });
            }
          }
        },
        initCurrentSelectDepartment: () => {
          const departments = [] as PermissionTargetDepartment[];
          if (this.state.departmentTreeData?.rootDepartment) {
            departments.push(this.state.departmentTreeData?.rootDepartment);
          }
          this.state.currentSelectDepartment = departments;
        },
        setSelectDepartmentId: (value: string) => {
          this.state.selectDepartmentId = value;
        },
      };
    }

    public effects() {
      return {
        initializeRequest: async () => {
          await this.getContainer(
            CompanyListModel
          ).actions.initialIterator.dispatch({});
          const user = await this.dependencies.oidcClient.getOidcUser();
          if (
            user?.profile.external_companyid ??
            user?.profile.default_companyid
          ) {
            await this.getContainer(
              AppModel
            ).actions.setCurrentCompanyId.dispatch(
              user?.profile.external_companyid ??
                user?.profile.default_companyid
            );
            const departmentTree = await this.getContainer(
              DepartmentHelperModel
            ).actions.getTree.dispatch(null);
            await this.actions.setDepartmentTreeData.dispatch(departmentTree);

            await this.getContainer(
              AppModel
            ).actions.setCurrentDepartmentId.dispatch(
              departmentTree.rootDepartment?.departmentId ?? ""
            );
            await this.getContainer(AppModel).actions.setWxConfig.dispatch({});
            await this.actions.setInitialized.dispatch(true);
          } else {
            await this.getContainer(AppModel).actions.login.dispatch({});
          }
        },
      };
    }
  }
);
