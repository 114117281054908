import { Theme, ThemeSet } from "src/styles/theme";

const main: Theme = {
  primaryColor: "#3377ff",
  lightBlue: "#5b8ff9",
  lightPrimaryColor: "#EDF2FB",
  defaultColor: "#8F9BB3",
  successColor: "#39bd65",
  rownColor: "#ffa033",
  warningColor: "#f05656",
  errorColor: "#e03200",
  failureColor: "#c0c0c0",
  currentSignBackgroundColor: "#ff5503",
  statusEnabledColor: "#39bd65",
  succuessText: "#5C9970",
  warningText: "#ab9467",
  errorText: "#cc6666",
  infoText: "#6699cc",
  strongText: "#1f2e4d",
  statusDisabledColor: "#d3dbeb",
  shadowColor: "rgba(0, 0, 0, 0.15)",

  inputBorder: "#d3dbeb",
  inputFocusShadow: "rgba(51,119,255,0.2)",
  inputDangerFocusShadowColor: "rgba(240,86,86,0.2)",

  bodyText: "#3d4966",
  bodySubtext: "#8f9bb3",
  bodyDivider: "#cad1e0",
  bodyBackground: "#ffffff",
  bodyFrameBackground: "#ebf2fc",
  bodyFrameDivider: "#dadee6",
  bodyStandoutBackground: "#f7f9fa",
  bodyFrameShadowColor: "rgba(61,73,102,0.2)",

  contentShadowColor: "rgba(61,73,102,0.1)",
  boxShadowColor: "rgba(139,139,139,0.1)",
  buttonText: "#cad1e0",
  buttonSpecial: "#8543e0",
  buttonBackground: "#ffffff",
  buttonBackgroundHovered: "#3377ff",
  buttonBackgroundChecked: "#edebe9",

  rectBackground: "rgba(0,0,0,0.2)",

  tableDivider: "#edf2fb",

  menuHoverBackground: "#5b92ff",
  menuSelectedBackground: "#84adff",

  canvasBackground: "#f7f9fc",

  primaryButtonText: "#ffffff",
  primaryButtonBackground: "#3377ff",
  primaryButtonBackgroundHovered: "#40a9ff",
  primaryButtonBackgroundChecked: "#3d6dcc",

  inputStandoutBackground: "#e6e9f0",
  mobile: {
    divider: "#000",
  },
};

export const lightThemeSet: ThemeSet = {
  header: {
    ...main,
    bodyBackground: "#ffffff",
    bodyFrameShadowColor: "rgba(143,155,179,0.2)",

    buttonText: "#ffffff",
  },
  body: {
    ...main,
    bodyBackground: ["#143066", "#141F33"],
    bodyText: "#ffffff",
    bodySubtext: "#ffffff",
    bodyDivider: "#dadee6",
    bodyFrameBackground: "#ffffff",
    menuHoverBackground: "#153687",
  },
};
