/** @jsx jsx */
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import { Button, Col, Modal, Row } from "antd";
import React, {
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useFormatMessage, useTheme } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { reactMemo } from "src/utils/react";

interface ConfigTypes {
  className?: string;
  type?: "error" | "info" | "success" | "warning";
  title?: string;
  content: ReactNode | string | null;
  cancelVisible?: boolean;
  okVisible?: boolean;
  headerVisible?: boolean;
  okText?: ReactNode | string;
  cancelText?: ReactNode | string;
  footer?: ReactNode | null;
  onOk?: () => void;
  onCancel?: () => void;
}
interface PackConfigTypes extends ConfigTypes {
  key: number;
}

interface AlertNodeProps {
  config: PackConfigTypes;
  destory: (key: number) => void;
}

interface FooterProps {
  okText?: ReactNode | string;
  cancelText?: ReactNode | string;
  cancelVisible?: boolean;
  okVisible?: boolean;
  onOk?: () => Promise<boolean | void>;
  onCancel?: () => Promise<boolean | void>;
}

export const Footer = reactMemo(function Footer(props: FooterProps) {
  const {
    okText,
    onOk,
    cancelText,
    onCancel,
    cancelVisible = true,
    okVisible = true,
  } = props;
  const formatMessage = useFormatMessage();

  return (
    <div
      css={css`
        button {
          height: 32px;
          margin-left: 20px !important;
          min-width: 88px;
          border-radius: 4px;
        }
      `}
    >
      {cancelVisible ? (
        <Button onClick={onCancel}>
          {cancelText || formatMessage(messageIds.common.cancel)}
        </Button>
      ) : null}
      {okVisible ? (
        <Button type="primary" onClick={onOk}>
          {okText || formatMessage(messageIds.common.confirm)}
        </Button>
      ) : null}
    </div>
  );
});

export type ContextTypes = {
  show: (config: ConfigTypes) => void;
} | null;

const Context = React.createContext<ContextTypes>(null);

export const AlertProvider = React.memo(function AlertPrivoder(props) {
  const [configs, setConfigs] = useState<PackConfigTypes[]>([]);
  const destoryHandler = useCallback(
    (key: number) => {
      setConfigs((configs) =>
        configs.filter((item: PackConfigTypes) => item.key !== key)
      );
    },
    [setConfigs]
  );
  const showFn = useCallback((config) => {
    setConfigs((configs) => [...configs, { key: Date.now(), ...config }]);
  }, []);
  return (
    <Context.Provider
      value={{
        show: showFn,
      }}
    >
      {configs.map((item: PackConfigTypes) => {
        return (
          <AlertNode
            key={item.key}
            config={item}
            destory={destoryHandler}
          ></AlertNode>
        );
      })}
      {props.children}
    </Context.Provider>
  );
});
const AlertNode = React.memo(function (props: AlertNodeProps) {
  const formatMessage = useFormatMessage();
  const theme = useTheme();
  const typeMap = {
    error: (
      <CloseCircleOutlined
        css={css`
          color: ${theme.errorColor};
        `}
      />
    ),
    info: (
      <InfoCircleOutlined
        css={css`
          color: ${theme.primaryColor};
        `}
      />
    ),
    success: (
      <CheckCircleOutlined
        css={css`
          color: ${theme.successColor};
        `}
      />
    ),
    warning: (
      <InfoCircleOutlined
        css={css`
          color: ${theme.rownColor};
        `}
      />
    ),
  };
  const { destory, config } = props;
  const {
    key,
    className,
    title = formatMessage(messageIds.common.alertTitle),
    okText = formatMessage(messageIds.common.confirm),
    content,
    headerVisible = true,
    type,
    cancelText = formatMessage(messageIds.common.cancel),
    onOk,
    onCancel,
    cancelVisible = true,
    okVisible = true,
    ...restProps
  } = config;
  const [visible, setVisible] = useState(true);
  const canceHandle = useCallback(async () => {
    const flag = (await onCancel?.()) || false;
    setVisible(flag);
    if (!flag) destory(key);
  }, [onCancel, destory, key]);

  const sureHandle = useCallback(async () => {
    const flag = (await onOk?.()) || false;
    setVisible(flag);
    if (!flag) destory(key);
  }, [destory, key, onOk]);

  const renderContent = useCallback(() => {
    return (
      <Row
        align="top"
        css={css`
          .alert-icon {
            font-size: 20px;
            line-height: 1;
          }
          .alert-content {
            flex: 1;
          }
        `}
      >
        {type ? (
          <Col className="alert-icon" flex="30px">
            {typeMap[type]}
          </Col>
        ) : null}
        <Col className="alert-content">{content}</Col>
      </Row>
    );
  }, [content, type, typeMap]);
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={canceHandle}
      css={css`
        .ant-modal-content {
          border-radius: 4px;
        }
        .ant-modal-body {
          padding: 5px 30px;
        }
        .ant-modal-header,
        .ant-modal-footer {
          border: none;
        }
        .ant-modal-footer {
          padding: 10px 30px;
        }
        .ant-modal-header {
          display: ${headerVisible ? "block" : "none"};
        }
      `}
      className={className}
      destroyOnClose={true}
      footer={
        <Footer
          cancelVisible={cancelVisible}
          okVisible={okVisible}
          okText={okText}
          onOk={sureHandle}
          cancelText={cancelText}
          onCancel={canceHandle}
        />
      }
      {...restProps}
    >
      {renderContent()}
    </Modal>
  );
});
export const useDialog = function useDialog() {
  const value = useContext(Context);
  return useCallback(
    (config: Omit<ConfigTypes, "headerVisible">) => {
      value?.show(config);
    },
    [value]
  );
};
export const useModal = function useDialog() {
  const value = useContext(Context);
  return useMemo(
    () => ({
      info: (config: Omit<ConfigTypes, "headerVisible" | "type">) => {
        value?.show({ headerVisible: false, type: "info", ...config });
      },
      error: (config: Omit<ConfigTypes, "headerVisible" | "type">) => {
        value?.show({ headerVisible: false, type: "error", ...config });
      },
      success: (config: Omit<ConfigTypes, "headerVisible" | "type">) => {
        value?.show({ headerVisible: false, type: "success", ...config });
      },
      warning: (config: Omit<ConfigTypes, "headerVisible" | "type">) => {
        value?.show({ headerVisible: false, type: "warning", ...config });
      },
    }),
    [value]
  );
};
