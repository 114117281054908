import { initQyWechatConfig } from "@encoo-web/encoo-ui";
import { createModel, createSelector } from "nyax";
import { Company } from "src/models/company";
import { ModelBase } from "src/store/ModelBase";
import { CompanyListModel } from "src/store/models/entity/company/list";
import { IdentityUser } from "src/store/models/ui/user/identityUser";

export const AppModel = createModel(
  class extends ModelBase {
    private get _client() {
      return this.dependencies.oidcClient;
    }
    public initialState() {
      return {
        isInitialized: false,
        currentIdentityUser: null as IdentityUser | null,
        currentCompanyId: "" as string,
        currentDepartmentId: "" as string,
        isWechatConfigSuccess: false,
        currentCompany: null as Company | null,
      };
    }

    public reducers() {
      return {
        initializeSuccess: () => {
          this.state.isInitialized = true;
        },
        setCurrentIdentityUser: (value: IdentityUser | null) => {
          this.state.currentIdentityUser = value;
        },
        setCurrentCompanyId: (id: string) => {
          this.state.currentCompanyId = id;
        },
        setCurrentDepartmentId: (id: string) => {
          this.state.currentDepartmentId = id;
        },
        setIsWechatConfigSuccess: (value: boolean) => {
          this.state.isWechatConfigSuccess = value;
        },
        setCurrentCompany: (value: Company) => {
          this.state.currentCompany = value;
        },
      };
    }

    public effects() {
      return {
        initializeRequest: async () => {
          await this.actions.initializeSuccess.dispatch({});
        },
        logout: async () => {
          if (this.state.currentIdentityUser) {
            await this._client.logOut();
            await this.actions.setCurrentIdentityUser.dispatch(null);
          }
        },
        login: async () => {
          const user = await this._client.login();
          await this.actions.setCurrentIdentityUser.dispatch(
            (user?.profile as unknown) as IdentityUser
          );
        },
        setCurrentCompanyId: async (id: string) => {
          this.dependencies.httpMiddleware.companyHeader.setHeaderValue(
            id ?? ""
          );
        },
        setCurrentDepartmentId: async (id: string) => {
          this.dependencies.httpMiddleware.departmentHeader.setHeaderValue(
            id ?? ""
          );
        },
        setWxConfig: async () => {
          if (!this.state.currentCompanyId) {
            return;
          }
          const companyFrom = this.getters.currentCompanyFrom;
          if (companyFrom === "QyWechat") {
            const ticket = await this.dependencies.serviceClient.appV2.GetTicket(
              this.state.currentCompanyId
            );
            await initQyWechatConfig({
              corpid: ticket.corpId,
              agentid: ticket.agentId,
              timestamp: ticket.timeStamp,
              nonceStr: ticket.nonceStr,
              signature: ticket.signature,
              corpSignature: ticket.corpSignature,
            });
            this.actions.setIsWechatConfigSuccess.dispatch(true);
          }
        },
      };
    }

    public selectors() {
      return {
        currentCompanyFrom: createSelector(
          () => this.getContainer(CompanyListModel).getters.dataSource,
          () => this.state.currentCompanyId,
          (companies, currentCompanyId): string => {
            const company = companies[0];
            if (!company) {
              return "";
            }
            const companyFrom = company.properties?.From;
            return companyFrom ?? "";
          }
        ),
      };
    }
  }
);
