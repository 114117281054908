/** @jsx jsx */
import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import React from "react";
import iconNoPermission from "src/assets/icon_no_permission.svg";
import iconNoResult from "src/assets/icon_no_result.svg";
import { useFormatMessage } from "src/hooks";
import messageIds from "src/locales/messageIds";

export default React.memo(function AppExplorerNoAppsPart(props: {
  hasPermission: boolean;
  hasFilter: boolean;
}) {
  const { hasPermission, hasFilter } = props;

  const formatMessage = useFormatMessage();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        padding: 20px;
      `}
    >
      <img src={!hasPermission ? iconNoPermission : iconNoResult} alt="" />
      <div
        css={css`
          font-size: 16px;
          margin-top: 13px;
        `}
      >
        {!hasPermission
          ? formatMessage(messageIds.apps.myApp.noPermissionText)
          : hasFilter
          ? formatMessage(messageIds.apps.myApp.appsNotFoundText)
          : formatMessage(messageIds.apps.myApp.noAppsText)}
      </div>
    </div>
  );
});
