import {
  HeaderMiddleware,
  HttpClient,
  Profile,
  RequestQueue,
} from "@encoo-web/encoo-lib";
import { EncooOidcClient } from "@encoo-web/oidc-client";
import { History } from "history";
import {
  CompanyHeaderMiddlewareSymbol,
  DepartmentHeaderMiddlewareSymbol,
  ErrorMiddlewareSymbol,
  HistorySymbol,
  HttpClientSymbol,
  ProfileSymbol,
  RequestQueueMiddlewareSymbol,
  TenantHeaderMiddlewareSymbol,
  UserManagerSettingsSymbol,
} from "src/dependencies/identifiers";
import developmentContainer from "src/dependencies/_development";
import productionContainer from "src/dependencies/_production";
import { AppsV2ServiceClient } from "src/services/appsV2Service";
import { CompanyServiceClient } from "src/services/Company";
import { CompanyUserServiceClient } from "src/services/CompanyUserService";
import { DepartmentServiceClient } from "src/services/DepartmentService";
import { LocaleService } from "src/services/LocaleService";
import { ReduxService } from "src/services/ReduxService";
import { ThemeService } from "src/services/ThemeService";
import { ErrorMiddleware } from "src/store/models/ui/error/error";

const container =
  process.env.NODE_ENV === "production"
    ? productionContainer
    : developmentContainer;

export interface Dependencies {
  userManagerSettings: Oidc.UserManagerSettings;
  history: History;
  locale: LocaleService;
  http: HttpClient;
  redux: ReduxService;
  theme: ThemeService;
  oidcClient: EncooOidcClient;
  profile: Profile;

  httpMiddleware: {
    tenantHeader: HeaderMiddleware;
    companyHeader: HeaderMiddleware;
    departmentHeader: HeaderMiddleware;
    requestQueue: RequestQueue;
    errorHandle: ErrorMiddleware;
  };

  serviceClient: {
    company: CompanyServiceClient;
    department: DepartmentServiceClient;
    appV2: AppsV2ServiceClient;
    companyUser: CompanyUserServiceClient;
  };
}

const dependencies: Dependencies = {
  get userManagerSettings() {
    return container.get<Oidc.UserManagerSettings>(UserManagerSettingsSymbol);
  },

  get http() {
    return container.get<HttpClient>(HttpClientSymbol);
  },

  get history() {
    return container.get<History>(HistorySymbol);
  },

  get locale() {
    return container.get(LocaleService);
  },
  get redux() {
    return container.get(ReduxService);
  },
  get theme() {
    return container.get(ThemeService);
  },

  get oidcClient() {
    return container.get(EncooOidcClient);
  },

  get profile() {
    return container.get<Profile>(ProfileSymbol);
  },

  httpMiddleware: {
    get tenantHeader() {
      return container.get<HeaderMiddleware>(TenantHeaderMiddlewareSymbol);
    },
    get companyHeader() {
      return container.get<HeaderMiddleware>(CompanyHeaderMiddlewareSymbol);
    },
    get departmentHeader() {
      return container.get<HeaderMiddleware>(DepartmentHeaderMiddlewareSymbol);
    },
    get requestQueue() {
      return container.get<RequestQueue>(RequestQueueMiddlewareSymbol);
    },
    get errorHandle() {
      return container.get<ErrorMiddleware>(ErrorMiddlewareSymbol);
    },
  },

  serviceClient: {
    get company() {
      return container.get(CompanyServiceClient);
    },
    get department() {
      return container.get(DepartmentServiceClient);
    },
    get appV2() {
      return container.get(AppsV2ServiceClient);
    },
    get companyUser() {
      return container.get(CompanyUserServiceClient);
    },
  },
};

export function getDependencies() {
  return dependencies;
}
