export default {
  tenant: {
    introduce: {
      community: {
        title: "社区版租户管理",
        description: "社区版租户管理主要用于管理并查看所有云扩社区版租户信息",
      },
      enterprise: {
        title: "企业版租户管理",

        description:
          "企业版租户管理主要用于管理并创建各个企业级租户，支持对单个租户部分系统配置进行管理。",
      },
    },
    title: "企业版租户管理",
    description:
      "企业版租户管理主要用于管理并创建各个企业级租户，支持对单个租户部分系统配置进行管理。",
    tenantId: "租户ID",
    name: "租户名称",
    tenantType: "租户类型",
    tenantInfo: "租户信息",
    AccountSetting: "账户设置",
    isFixedPostfixMailbox: "是否限制租户内账户为固定后缀邮箱",
    mailboxPostfixConfiguration: "邮箱后缀配置",
    addSuffix: "添加后缀",
    addSuffixTip: "点击添加按钮添加后缀",
    initializeAdministratorAccount: "初始化管理员账号",
    initializeAdministratorPassword: "初始化管理员密码",
    setting: {
      name: "基本信息",
      title: "租户基本信息",
      customerIdentifier: "客户ID（标识码）",
    },
    validate: {
      inputTenantName: "请输入租户名称",
      inputPrefix: "请输入邮箱前缀",
      inputSuffix: "请输入邮箱后缀",
      prefixErr: "邮箱前缀格式错误",
      suffixErr: "邮箱后缀格式错误",
      selectSuffix: "请选择邮箱后缀",
      emailSuffixRepeat: "邮箱后缀重复",
      addEmailSuffix: "请添加邮箱后缀",
    },
  },
};
