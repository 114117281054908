/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { DepartmentName } from "@encoo-web/encoo-ui";
import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import departmentSrc from "src/assets/department.png";
import goBackSrc from "src/assets/goback.png";
import src from "src/assets/toggle.png";
import Button from "src/components/Button";
import { useGetContainer } from "src/hooks";
import { PermissionTargetDepartment } from "src/models/department";
import { AppModel } from "src/store/models/app";
import { HeaderUIModel } from "src/store/models/ui/home/header";

export default React.memo(function DepartmentSelect(props: {
  companyFrom: string;
}) {
  const { companyFrom } = props;
  const getContainer = useGetContainer();
  const appModel = getContainer(AppModel);
  const headerUIModel = getContainer(HeaderUIModel);

  const departments = useSelector(
    () => headerUIModel.state.currentSelectDepartment
  );
  const isShowSelect = useSelector(() => headerUIModel.state.isShowSelect);

  const selectDepartment = useCallback(
    (currentDepartment: PermissionTargetDepartment) => {
      if (currentDepartment.children && currentDepartment.children.length > 0) {
        headerUIModel.actions.setCurrentSelectDepartment.dispatch(
          currentDepartment.children
        );
      } else {
        headerUIModel.actions.setSelectDepartmentId.dispatch(
          currentDepartment.id
        );
      }
    },
    [
      headerUIModel.actions.setCurrentSelectDepartment,
      headerUIModel.actions.setSelectDepartmentId,
    ]
  );

  const close = useCallback(() => {
    headerUIModel.actions.setIsShowSelect.dispatch(false);
  }, [headerUIModel.actions.setIsShowSelect]);

  const selectDepartmentId = useSelector(
    () => headerUIModel.state.selectDepartmentId
  );

  useEffect(() => {
    appModel.actions.setWxConfig.dispatch({});
  });

  const onChange = useCallback(
    (e: RadioChangeEvent) => {
      headerUIModel.actions.setSelectDepartmentId.dispatch(e.target.value);
    },
    [headerUIModel.actions.setSelectDepartmentId]
  );

  const goBack = useCallback(() => {
    headerUIModel.actions.goBack.dispatch({});
  }, [headerUIModel.actions.goBack]);

  const onSubmit = useCallback(() => {
    appModel.actions.setCurrentDepartmentId.dispatch(selectDepartmentId);
    headerUIModel.actions.setIsShowSelect.dispatch(false);
  }, [
    appModel.actions.setCurrentDepartmentId,
    headerUIModel.actions.setIsShowSelect,
    selectDepartmentId,
  ]);

  return isShowSelect ? (
    <div
      css={css`
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        /* background: rgba(0, 0, 0, 0.5); */
        z-index: 1;
      `}
    >
      <div
        css={css`
          height: 52px;
          width: 100%;
          background: white;
          text-align: left;
        `}
      >
        <div onClick={goBack}>
          <img
            alt=""
            src={goBackSrc}
            css={css`
              width: 8px;
              height: 14px;
              margin-top: 14px;
              margin-left: 20px;
              margin-right: 10px;
              margin-bottom: 14px;
            `}
          ></img>
          返回
        </div>
        <div
          css={css`
            width: 100%;
            height: 10px;
            background: #f8f8f8;
          `}
        ></div>
      </div>
      <div
        css={css`
          position: absolute;
          top: 52px;
          width: 100%;
          overflow-y: auto;
          opacity: 1;
          bottom: 45px;
          background-color: white;
        `}
      >
        <Radio.Group
          onChange={(e) => onChange(e)}
          css={css`
            position: absolute;
            left: 0;
            width: 100%;
          `}
          value={selectDepartmentId}
        >
          {departments.map((department) => (
            <div
              css={css`
                width: 100%;
                height: 40px;
                padding-left: 20px;
                font-size: 18px;
                border: 0px;
                text-align: left;
                display: inline-flex;
              `}
            >
              <Radio
                value={department.id}
                css={css`
                  margin-top: 10px;
                  width: 20px;
                `}
              ></Radio>
              <div
                onClick={() => selectDepartment(department)}
                css={css`
                  width: 90%;
                  display: inline-flex;
                  flex-wrap: wrap;
                  padding-top: 8px;
                `}
              >
                <img
                  alt=""
                  src={departmentSrc}
                  css={css`
                    width: 28px;
                    height: 28px;
                    margin-top: 0px;
                    margin-right: 5px;
                  `}
                ></img>
                <DepartmentName
                  openid={department.name ?? ""}
                  companyFrom={companyFrom}
                />

                {department.children && department.children.length > 0 && (
                  <img
                    alt=""
                    src={src}
                    css={css`
                      width: 8px;
                      height: 14px;
                      margin-top: 8px;
                      position: absolute;
                      right: 20px;
                    `}
                  ></img>
                )}
              </div>
            </div>
          ))}
        </Radio.Group>
      </div>
      <div
        css={css`
          position: absolute;
          bottom: 0px;
          width: 100%;
        `}
      >
        <Button
          css={css`
            width: 50%;
            height: 45px;
          `}
          onClick={() => close()}
        >
          关闭
        </Button>
        <Button
          css={css`
            width: 50%;
            height: 45px;
            color: white;
            background: #69a0f3;
          `}
          onClick={onSubmit}
        >
          确认
        </Button>
      </div>
    </div>
  ) : (
    <div></div>
  );
});
