import { ConvertRouteInfo, createRouteDefinition } from "src/routes/_shared";

export const appRouteDefinitions = {
  index: createRouteDefinition({
    type: "index",
    path: "/",
    component: "./home/Home",
    defaultParams: {},
  }),

  enterpriseTenant: createRouteDefinition({
    type: "enterpriseTenant",
    path: "/tenant/enterprise",
    component: "./tenant/TenantEnterpriseWrapper",
    defaultParams: {},
  }),
  communityTenant: createRouteDefinition({
    type: "communityTenant",
    path: "/tenant/community",
    component: "./tenant/TenantCommunityWrapper",
    defaultParams: {},
  }),
  enterpriseInstallation: createRouteDefinition({
    type: "enterpriseInstallation",
    path: "/installation/enterprise",
    component: "./installation/InstallationEnterpriseWrapper",
    defaultParams: {
      editionType: "enterprise",
    },
  }),
  communityInstallation: createRouteDefinition({
    type: "communityInstallation",
    path: "/installation/community",
    component: "./installation/InstallationCommunityWrapper",
    defaultParams: {
      editionType: "community",
    },
  }),

  communityTenantDetail: createRouteDefinition({
    type: "communityTenantDetail",
    path: "/tenant/edition/:editionType/communityTenantDetail",
    component: "./tenant/TenantDetail",
    silderComponent: "./tenant/TenantCommunityWrapper",
    defaultParams: {
      id: "",
      editionType: "",
    },
  }),
  enterpriseTenantDetail: createRouteDefinition({
    type: "enterpriseTenantDetail",
    path: "/tenant/edition/:editionType/enterpriseTenantDetail",
    component: "./tenant/TenantDetail",
    silderComponent: "./tenant/TenantEnterpriseWrapper",
    defaultParams: {
      id: "",
      editionType: "",
    },
  }),

  callback: createRouteDefinition({
    type: "callback",
    path: "/callback",
    component: "./callback/Callback",
    defaultParams: {},
  }),
  clear: createRouteDefinition({
    type: "clear",
    path: "/clear",
    component: "./callback/Clear",
    defaultParams: {},
  }),
  "404": createRouteDefinition({
    type: "404",
    path: "",
    component: "todo",
    defaultParams: {},
  }),
};

export type AppRouteDefinitions = typeof appRouteDefinitions;
export type AppRouteInfo = {
  [K in keyof AppRouteDefinitions]: ConvertRouteInfo<AppRouteDefinitions[K]>;
}[keyof AppRouteDefinitions];
