export default {
  menu: {
    name:"云扩RPA管理后台",
    tenant: {
      name: "租户管理",
      community: {
        name: "社区版租户",
      },
      enterprise: {
        name: "企业版租户",
      },
    },
    installation: {
      name: "安装包管理",
      community: {
        name: "企业版安装包管理",
      },
      enterprise: {
        name: "社区版安装包管理",
      },
    },
  },
};
