import { MyAppUIModel } from "src/store/models/ui/apps/myApp";
import { HeaderUIModel } from "src/store/models/ui/home/header";
import { LoadingUIModel } from "src/store/models/ui/loading/loading";
import { LocaleUIModel } from "src/store/models/ui/locale/locale";
import { ErrorUIModel } from "./error/error";

export const uiModels = {
  local: LocaleUIModel,
  Loading: LoadingUIModel,
  error: ErrorUIModel,
  myApp: MyAppUIModel,
  header: HeaderUIModel,
};
