import "core-js";
import { enableES5 } from "immer";
import Oidc from "oidc-client";
import React from "react";
import ReactDOM from "react-dom";
import AppWrapper from "src/AppWrapper";
import { consts } from "src/consts";
import App from "src/containers/App";
import Clear from "src/containers/callback/Clear";
import "src/iconFont";
import * as serviceWorker from "src/serviceWorker";

enableES5();

if (
  window.location.pathname?.endsWith(consts.settings.OidcSilentRenewAfterfix)
) {
  new Oidc.UserManager({
    // eslint-disable-next-line @typescript-eslint/camelcase
    response_mode: "query",
    scope: consts.settings.OidcScope,
    userStore: new Oidc.WebStorageStateStore({
      store: window.localStorage,
    }),
  }).signinSilentCallback();
}
if (window.location.pathname?.endsWith("clear")) {
  ReactDOM.render(
    <React.StrictMode>
      <AppWrapper>
        <Clear />
      </AppWrapper>
    </React.StrictMode>,
    document.getElementById("root")
  );
  serviceWorker.unregister();
} else {
  ReactDOM.render(
    <React.StrictMode>
      <AppWrapper>
        <App />
      </AppWrapper>
    </React.StrictMode>,
    document.getElementById("root")
  );
  serviceWorker.unregister();
}
