import { createModel } from "nyax";
import { AppV2 } from "src/models/appV2";
import { AppEntityModel } from "src/store/models/entity/apps/entity";
import { createListModel } from "src/store/models/entity/_shared";

export const AppListModel = createModel(
  class extends createListModel<AppV2>({
    setItems: (getContainer, items) =>
      getContainer(AppEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(AppEntityModel).getters.items,
    getItem: (getContainer, id) => getContainer(AppEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initialIterator: async (payload: { force?: boolean }) => {
          const { force = true } = payload;
          this._initialIterator({
            initialAction: () => this.dependencies.serviceClient.appV2.list(),
            force: force,
          });
        },
      };
    }
  }
);
