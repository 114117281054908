import { createModel, createSelector } from "nyax";
import { AppV2 } from "src/models/appV2";
import { ModelBase } from "src/store/ModelBase";
import { AppModel } from "src/store/models/app";
import { AppEntityModel } from "src/store/models/entity/apps/entity";
import { AppHelperModel } from "src/store/models/entity/apps/helper";

export const MyAppUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        searchText: "",
        pageNumber: 1,
        pageSize: 10,
      };
    }

    public selectors() {
      return {
        permissionId: createSelector(() => ""),
        hasPermission: createSelector(
          (): string => this.getters.permissionId,
          () => "permissionById",
          (permissionId, permissionById) => {
            return true; //todo 权限对接
          }
        ),

        hasFilter: createSelector(
          (): string => this.state.searchText,
          (searchText) => {
            return !!searchText;
          }
        ),

        apps: createSelector(
          (): boolean => this.getters.hasPermission,
          () => this.getContainer(AppModel).state.currentDepartmentId,
          () => this.getContainer(AppEntityModel).getters.items,
          () => this.state.searchText,
          (hasPermission, selectedDepartmentId, apps, searchText): AppV2[] => {
            if (!hasPermission) {
              return [];
            }

            let result = apps.slice();

            result = result.filter(
              (app) => app.departmentId === selectedDepartmentId
            );

            result = result.filter((app) => app.status === "Enabled");

            if (searchText) {
              result = result.filter((app) =>
                app.name.toLowerCase().includes(searchText.toLowerCase())
              );
            }
            result = result.sort((a, b) => {
              return (
                new Date(b.createdAt ?? "").getTime() -
                new Date(a.createdAt ?? "").getTime()
              );
            });
            return result;
          }
        ),

        pageApps: createSelector(
          (): AppV2[] => this.getters.apps,
          () => this.state.pageNumber,
          () => this.state.pageSize,
          (apps, pageNumber, pageSize) => {
            const startIndex = (pageNumber - 1) * pageSize;
            const endIndex = startIndex + pageSize;
            return apps.slice(startIndex, endIndex);
          }
        ),

        isAppsLoading: () => {
          return false;
        },
      };
    }

    public reducers() {
      return {
        setSearchText: (value: string) => {
          this.state.searchText = value;
        },
        setPageNumber: (value: number) => {
          this.state.pageNumber = value;
        },
      };
    }

    public effects() {
      return {
        initializeRequest: async () => {
          await this.getContainer(AppHelperModel).actions.getAll.dispatch({});
        },
        sendEmail: async (payload: {
          title: string;
          content: string;
          receiverEmail: string;
        }) => {
          const { title, content, receiverEmail } = payload;
          await this.dependencies.serviceClient.appV2.SendEmail(
            title,
            content,
            receiverEmail
          );
        },
      };
    }
  }
);
