/** @jsx jsx */
import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import { DepartmentName } from "@encoo-web/encoo-ui";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import switchIcon from "src/assets/switch.png";
import DepartmentSelect from "src/containers/home/components/DepartmentSelect";
import { ThemeContext } from "src/contexts";
import { useGetContainer, useTheme, useThemeSet } from "src/hooks";
import { AppModel } from "src/store/models/app";
import { HeaderUIModel } from "src/store/models/ui/home/header";

const HeaderInner = React.memo(function HeaderInner() {
  const getContainer = useGetContainer();

  const headerUIModel = getContainer(HeaderUIModel);
  const appUIModel = getContainer(AppModel);

  const companyFrom = useSelector(() => appUIModel.getters.currentCompanyFrom);

  useEffect(() => {
    headerUIModel.actions.initializeRequest.dispatch({});
  }, [appUIModel.actions.setWxConfig, headerUIModel.actions.initializeRequest]);

  const currentDepartmentName = useSelector(
    () => headerUIModel.getters.getCurrentDepartmentName
  );
  const currentDepartmentId = useSelector(
    () => appUIModel.state.currentDepartmentId
  );

  const isShowSelect = useSelector(() => headerUIModel.state.isShowSelect);

  const departmentSelect = useCallback(() => {
    headerUIModel.actions.setIsShowSelect.dispatch(true);
    headerUIModel.actions.initCurrentSelectDepartment.dispatch({});
    headerUIModel.actions.setSelectDepartmentId.dispatch(currentDepartmentId);
  }, [
    currentDepartmentId,
    headerUIModel.actions.initCurrentSelectDepartment,
    headerUIModel.actions.setIsShowSelect,
    headerUIModel.actions.setSelectDepartmentId,
  ]);
  return (
    <div
      css={css`
        width: 100%;
        height: 42px;
        text-align: center;
        padding: 12px;
      `}
    >
      <span onClick={departmentSelect}>
        <DepartmentName
          openid={currentDepartmentName ?? ""}
          companyFrom={companyFrom}
        />
        <img
          src={switchIcon}
          alt=""
          css={css`
            width: 16px;
            margin-left: 5px;
          `}
        ></img>
      </span>
      {isShowSelect && <DepartmentSelect companyFrom={companyFrom} />}
    </div>
  );
});

export default React.memo(function Header() {
  const themeSet = useThemeSet();
  const theme = useTheme();
  const getContainer = useGetContainer();

  const app = getContainer(HeaderUIModel);
  useEffect(() => {
    app.actions.initializeRequest.dispatch({});
  }, [app.actions.initializeRequest]);
  return (
    <ThemeContext.Provider value={themeSet.header}>
      <header
        css={css`
          display: flex;
          justify-content: space-between;
          width: 100%;
          min-height: 42px;
          padding-left: 20px;
          padding-right: 20px;
          background-color: ${theme.bodyBackground};
          text-align: center;
        `}
      >
        <HeaderInner></HeaderInner>
      </header>
    </ThemeContext.Provider>
  );
});
