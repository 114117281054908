/** @jsx jsx */
import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import favoriteIcon from "src/assets/favorite.png";
import notFavoriteIcon from "src/assets/notFavorite.png";
import shareURLIcon from "src/assets/shareURL.png";
import { useFormatMessage, useGetContainer } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { AppV2 } from "src/models/appV2";
import { AppModel } from "src/store/models/app";
import { AppHelperModel } from "src/store/models/entity/apps/helper";
import { MyAppUIModel } from "src/store/models/ui/apps/myApp";
import { formatDate } from "src/utils/string";

export default React.memo(function AppExplorerMobileCard(props: {
  app: AppV2;
  onShare: () => void;
}) {
  const { app, onShare: onShareProp } = props;
  const formatMessage = useFormatMessage();
  const getContainer = useGetContainer();
  const userApps = getContainer(MyAppUIModel);
  const application = getContainer(AppModel);
  const appsHelper = getContainer(AppHelperModel);

  const selectCompanyId = useSelector(() => application.state.currentCompanyId);
  const selectDepartmentId = useSelector(
    () => application.state.currentDepartmentId
  );

  const onClick = useCallback(
    (e) => {
      const url = `${window._settings.appsUrl}app?companyId=${selectCompanyId}&departmentId=${selectDepartmentId}&appId=${app.id}&appVersionId=${app.currentVersionId}`;
      window.location.href = url;
    },
    [app.id, app.currentVersionId, selectCompanyId, selectDepartmentId]
  );

  const onFavorite = useCallback(
    async (e) => {
      e.stopPropagation();
      if (app.isFavorite) {
        await appsHelper.actions.deleteFavorite.dispatch({ appId: app.id });
      } else {
        await appsHelper.actions.addFavorite.dispatch({ appId: app.id });
      }
      userApps.actions.initializeRequest.dispatch({});
    },
    [
      app.id,
      app.isFavorite,
      appsHelper.actions.addFavorite,
      appsHelper.actions.deleteFavorite,
      userApps.actions.initializeRequest,
    ]
  );

  const starIcon = useMemo(
    () => (app.isFavorite ? favoriteIcon : notFavoriteIcon),
    [app.isFavorite]
  );

  const onShare = useCallback(
    (e) => {
      e.stopPropagation();
      onShareProp();
    },
    [onShareProp]
  );

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        padding: 20px 24px;
        width: 100%;
        height: 96px;
        background: #fff;
      `}
      onClick={onClick}
    >
      <div
        css={css`
          display: flex;
        `}
      >
        <img
          css={css`
            width: 48px;
            height: auto;
          `}
          src={app.icon || "./app_icons/default.png"}
          alt={app.name}
        />
        <div
          css={css`
            padding-left: 12px;
          `}
        >
          <div
            css={css`
              line-height: 12px;
              color: #1f2e4d;
              font-weight: 600;
            `}
          >
            {app.name}
          </div>
          <div
            css={css`
              margin-top: 10px;
              line-height: 12px;
              color: #979ca4;
            `}
          >
            {formatMessage(messageIds.apps.myApp.currentVersion)}：
            {app.currentVersion}
          </div>
          <div
            css={css`
              margin-top: 10px;
              line-height: 12px;
              color: #979ca4;
            `}
          >
            {formatMessage(messageIds.apps.myApp.lastUpdate)}:
            {formatDate(app.modifiedAt)}
          </div>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <img
          src={starIcon}
          alt=""
          onClick={onFavorite}
          css={css`
            width: 24px;
            height: 24px;
          `}
        />
        <img
          src={shareURLIcon}
          alt={app.name}
          onClick={onShare}
          css={css`
            margin-top: 12px;
            width: 24px;
            height: 24px;
          `}
        />
      </div>
    </div>
  );
});
