/** @jsx jsx */
import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import { Pagination, Spin } from "antd";
import React, { Fragment, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import AppExplorerCard from "src/containers/apps/components/AppExplorerCard";
import AppExplorerNoAppsPart from "src/containers/apps/components/AppExplorerNoAppsPart";
import { useGetContainer } from "src/hooks";
import { MyAppUIModel } from "src/store/models/ui/apps/myApp";

export default React.memo(function MyApp() {
  const getContainer = useGetContainer();
  const userApps = getContainer(MyAppUIModel);

  const hasFilter = useSelector(() => userApps.getters.hasFilter);
  const hasPermission = useSelector(() => userApps.getters.hasPermission);
  const isAppsLoading = useSelector(() => userApps.getters.isAppsLoading);
  const pageNumber = useSelector(() => userApps.state.pageNumber);
  const pageSize = useSelector(() => userApps.state.pageSize);
  const apps = useSelector(() => userApps.getters.apps);
  const pageApps = useSelector(() => userApps.getters.pageApps);

  const onPageNumberChange = useCallback(
    (value: number) => userApps.actions.setPageNumber.dispatch(value),
    [userApps]
  );

  useEffect(() => {
    userApps.actions.initializeRequest.dispatch({});
  }, [userApps.actions.initializeRequest]);

  return (
    <Fragment>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          min-height: 0;
        `}
      >
        {!isAppsLoading ? (
          pageApps.length > 0 ? (
            <Fragment>
              <div
                css={css`
                  display: flex;
                  flex-wrap: wrap;
                  flex-grow: 1;
                  padding: 20px;
                  overflow-y: auto;
                `}
              >
                {pageApps.map((app) => (
                  <AppExplorerCard key={app.id} app={app} />
                ))}
              </div>
              <Pagination
                css={css`
                  margin: 20px;
                `}
                current={pageNumber}
                pageSize={pageSize}
                total={apps.length}
                showSizeChanger={false}
                onChange={onPageNumberChange}
              />
            </Fragment>
          ) : (
            <AppExplorerNoAppsPart
              hasPermission={hasPermission}
              hasFilter={hasFilter}
            />
          )
        ) : (
          <Spin
            css={css`
              margin: auto;
            `}
          />
        )}
      </div>
    </Fragment>
  );
});
