import { localStoreKey } from "src/consts";

export const localStore = {
  set: (key: localStoreKey, item: string | object) => {
    const storeValue = typeof item === "string" ? item : JSON.stringify(item);
    localStorage.setItem(key, storeValue);
  },
  get: (key: localStoreKey) => {
    const storeValue = localStorage.getItem(key);
    try {
      if (storeValue && typeof JSON.parse(storeValue) == "object") {
        return JSON.parse(storeValue);
      } else {
        return storeValue;
      }
    } catch (e) {
      return storeValue;
    }
  },
  remove: (key: localStoreKey) => {
    localStorage.removeItem(key);
  },
  clear: () => {
    localStorage.clear();
  },
};
