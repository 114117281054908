/** @jsx jsx */
import { Global, jsx } from "@emotion/core";
import { ConfigProvider } from "antd";
import { ConnectedRouter } from "connected-react-router";
import React, { useState } from "react";
import { RawIntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { AlertProvider } from "src/components/Dialog";
import { DependenciesContext, ThemeContext } from "src/contexts";
import { getDependencies } from "src/dependencies";
import cssGlobal from "src/styles/global";

export default React.memo(function AppWrapper(
  props: React.PropsWithChildren<{}>
) {
  const { children } = props;

  const [dependencies] = useState(getDependencies());
  const [history] = useState(dependencies.history);
  const [intl] = useState(dependencies.locale.intl);
  const [antdLocale] = useState(dependencies.locale.antdLocale);
  const [store] = useState(dependencies.redux.store);

  return (
    <DependenciesContext.Provider value={dependencies}>
      <RawIntlProvider value={intl}>
        <ConfigProvider locale={antdLocale}>
          <Provider store={store}>
            <ThemeContext.Provider value={dependencies.theme.themeSet.body}>
              <AlertProvider>
                <ConnectedRouter history={history}>
                  <Global styles={cssGlobal} />
                  {children}
                </ConnectedRouter>
              </AlertProvider>
            </ThemeContext.Provider>
          </Provider>
        </ConfigProvider>
      </RawIntlProvider>
    </DependenciesContext.Provider>
  );
});
