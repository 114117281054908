import { useCallback, useContext } from "react";
import { useIntl } from "react-intl";
import { DependenciesContext, ThemeContext } from "src/contexts";

export function useDependencies() {
  return useContext(DependenciesContext);
}

export function useGetContainer() {
  return useDependencies().redux.getContainer;
}

export function useThemeSet() {
  return useDependencies().theme.themeSet;
}

export function useTheme() {
  return useContext(ThemeContext);
}

export function useFormatMessage() {
  const intl = useIntl();
  return useCallback(
    (messageId: string, values?: Record<string, string | number>) => {
      return intl.formatMessage(
        {
          id: messageId,
          defaultMessage: messageId,
        },
        values
      );
    },
    [intl]
  );
}
