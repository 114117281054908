import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import {
  Department,
  DepartmentCreationData,
  DepartmentTreeData,
  DepartmentUpdateData,
} from "src/models/department";

export const DEPARTMENT_SERVICE_NAME = "department";

export class DepartmentServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, DEPARTMENT_SERVICE_NAME);
  }

  async getTree(): Promise<DepartmentTreeData> {
    const req = this.buildRequest({
      url: `/v2/departments/tree`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getById(departmentId: string): Promise<Department> {
    const req = this.buildRequest({
      url: `/v2/departments/${departmentId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async create(payload: DepartmentCreationData): Promise<Department> {
    const req = this.buildRequest({
      url: `/v2/departments`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  async update(
    departmentId: string,
    payload: DepartmentUpdateData
  ): Promise<Department> {
    const req = this.buildRequest({
      url: `/v2/departments/${departmentId}`,
      method: "PATCH",
      payload,
    });
    return await super.updateInternal(req);
  }

  async delete(departmentId: string): Promise<boolean> {
    const req = this.buildRequest({
      url: `/v2/departments/${departmentId}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }
}
