import { AccessToken, ServiceCredential } from "@encoo-web/encoo-lib/types";
import { EncooOidcClient } from "@encoo-web/oidc-client";
import { UserManagerSettings } from "oidc-client";

export class OidcClientAuthenticationCredential implements ServiceCredential {
  private OidcClient: EncooOidcClient;
  constructor(config: UserManagerSettings) {
    this.OidcClient = new EncooOidcClient(config);
  }
  getAccessToken(): Promise<AccessToken | null> {
    return this.OidcClient.login().then((user) => ({
      token: user?.access_token || "",
      expiresOnTimestamp: user?.expires_at || 0,
    }));
  }
}
