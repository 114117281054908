import apps from "src/locales/zh-CN/apps";
import common from "src/locales/zh-CN/common";
import layout from "src/locales/zh-CN/layout";
import tenant from "src/locales/zh-CN/tenant";
export default {
  ...common,
  ...layout,
  ...tenant,
  ...apps,
};
