import { createModel } from "nyax";
import { Company } from "src/models/company";
import { CompanyEntityModel } from "src/store/models/entity/company/entity";
import { CompanyListModel } from "src/store/models/entity/company/list";
import { createHelperModel } from "src/store/models/entity/_shared";

export const CompanyHelperModel = createModel(
  class extends createHelperModel<Company>({
    setItems: (getContainer, items) =>
      getContainer(CompanyEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(CompanyEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(CompanyEntityModel).state.byId[id],
    refreshList: (getContainer) =>
      getContainer(CompanyListModel).actions.refresh.dispatch({}),
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(CompanyEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),
      };
    }
  }
);
