import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { getLocale, setLocale } from "src/utils/locale";

export const LocaleUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        selectedLocal: getLocale(),
      };
    }

    public reducers() {
      return {
        ...super.reducers(),
        setSelectedLocale: (value: string) => {
          setLocale(value);
        },
      };
    }
  }
);
