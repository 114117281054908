import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import { Company } from "src/models/company";

export const COMPANY_SERVICE_NAME = "company";

export class CompanyServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, COMPANY_SERVICE_NAME);
  }

  async getList() {
    const req = this.buildRequest({
      url: `/v2/companies`,
      method: "GET",
    });
    return super.listInternally<Company>(req);
  }
}
