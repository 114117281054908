export default {
  menu: {
    tenant: {
      name: "租户管理",
      community: {
        name: "社区版租户",
      },
      enterprise: {
        name: "企业版租户",
      },
    },
  },
};
