/** @jsx jsx */
import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import { Drawer, Snackbar } from "@material-ui/core";
import { Form, Input, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import copy from "copy-to-clipboard";
import QRCode from "qrcode.react";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "src/components/Button";
import { useDialog } from "src/components/Dialog";
import MobileAppExplorerCard from "src/containers/apps/components/MobileAppExplorerCard";
import { useFormatMessage, useGetContainer } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { AppV2 } from "src/models/appV2";
import { AppModel } from "src/store/models/app";
import { MyAppUIModel } from "src/store/models/ui/apps/myApp";

export default React.memo(function MyAppMobile() {
  const getContainer = useGetContainer();
  const [emailForm] = Form.useForm();
  const Dialog = useDialog();
  const formatMessage = useFormatMessage();
  const userApps = getContainer(MyAppUIModel);
  const application = getContainer(AppModel);
  const isAppsLoading = useSelector(() => userApps.getters.isAppsLoading);
  const apps = useSelector(() => userApps.getters.apps);
  const [showShareModal, setShowShareModal] = useState(false);
  const selectCompanyId = useSelector(() => application.state.currentCompanyId);
  const selectDepartmentId = useSelector(
    () => application.state.currentDepartmentId
  );
  const [toastText, setToastText] = useState("");

  const [currentApp, setCurrentApp] = useState<AppV2 | null>(null);
  const [showToast, setShowToast] = useState(false);

  const toggleDrawer = useCallback((e) => {
    setShowShareModal(false);
  }, []);

  const onShare = useCallback((app: AppV2) => {
    setShowShareModal(true);
    setCurrentApp(app);
  }, []);

  const shareUrl = useCallback(
    (app: AppV2 | null) => {
      if (!app) return "";
      const searchParams = new URLSearchParams();
      selectCompanyId && searchParams.set("companyId", selectCompanyId);
      selectDepartmentId &&
        searchParams.set("departmentId", selectDepartmentId);
      searchParams.set("appId", app.id);
      app.currentVersionId &&
        searchParams.set("appVersionId", app.currentVersionId);
      app.accessKey && searchParams.set("accessKey", app.accessKey);
      return `${window._settings.appsUrl}app?${searchParams.toString()}`;
    },
    [selectCompanyId, selectDepartmentId]
  );

  const downloadQrCode = useCallback(() => {
    if (!currentApp) return;
    const canvasImg = document.getElementById(
      `${currentApp.id}_${currentApp.lastVersionId}`
    );
    if (canvasImg) {
      const canvas = canvasImg as HTMLCanvasElement;
      const a = document.createElement("a");
      a.download = `${currentApp.name}.png`;
      a.href = canvas.toDataURL("image/png");
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  }, [currentApp]);

  const onCopy = useCallback(() => {
    if (currentApp) {
      copy(shareUrl(currentApp));
      setShowToast(true);
      setShowShareModal(false);
      setToastText("链接已复制，快去黏贴分享吧~");
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
    }
  }, [currentApp, shareUrl]);

  const openScanQrCode = useCallback(() => {
    setShowShareModal(false);
    Dialog({
      title: formatMessage(messageIds.apps.myApp.card.menu.qrCodeShare),
      content: (
        <div
          css={css`
            text-align: center;
            padding-bottom: 20px;
          `}
        >
          <QRCode
            id={`${currentApp?.id}_${currentApp?.lastVersionId}`}
            value={shareUrl(currentApp)}
            size={200}
            css={css`
              margin: 10px;
            `}
          />
          <br />
          <Button
            type="primary"
            css={css`
              width: 200px;
            `}
            onClick={downloadQrCode}
          >
            保存图片
          </Button>
        </div>
      ),
      footer: null,
    });
  }, [Dialog, currentApp, downloadQrCode, formatMessage, shareUrl]);

  const openSendEmail = useCallback(() => {
    setShowShareModal(false);
    emailForm.resetFields();
    emailForm.setFields([
      {
        name: "title",
        value: formatMessage(messageIds.apps.myApp.card.menu.emailTitle),
      },
    ]);
    emailForm.setFields([
      {
        name: "content",
        value: formatMessage(messageIds.apps.myApp.card.menu.emailContent, {
          shareUrl: shareUrl(currentApp),
        }),
      },
    ]);
    Dialog({
      title: formatMessage(messageIds.apps.myApp.card.menu.emailShare),
      content: (
        <Form colon={false} layout="vertical" form={emailForm}>
          <Form.Item
            label={formatMessage(messageIds.apps.myApp.card.menu.recevierEmail)}
            name="receiverEmail"
            rules={[
              {
                required: true,
                message: formatMessage(messageIds.common.required),
              },
              {
                type: "email",
                validateTrigger: "onBlur",
              },
            ]}
          >
            <Input placeholder={formatMessage(messageIds.common.placeholder)} />
          </Form.Item>
          <Form.Item
            label={formatMessage(messageIds.common.title)}
            name="title"
            rules={[
              {
                required: true,
                message: formatMessage(messageIds.common.required),
              },
            ]}
          >
            <Input
              // disabled={true}
              placeholder={formatMessage(messageIds.common.placeholder)}
            />
          </Form.Item>
          <Form.Item
            label={formatMessage(messageIds.common.content)}
            name="content"
            rules={[
              {
                required: true,
                message: formatMessage(messageIds.common.required),
              },
            ]}
          >
            <TextArea
              // disabled={true}
              rows={8}
              placeholder={formatMessage(messageIds.common.placeholder)}
            />
          </Form.Item>
        </Form>
      ),
      onOk: async () => {
        const {
          title,
          content,
          receiverEmail,
        } = await emailForm.validateFields();

        await userApps.actions.sendEmail.dispatch({
          title,
          content,
          receiverEmail,
        });

        setToastText("发送成功！");
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 3000);
      },
    });
  }, [
    Dialog,
    currentApp,
    emailForm,
    formatMessage,
    shareUrl,
    userApps.actions.sendEmail,
  ]);

  useEffect(() => {
    userApps.actions.initializeRequest.dispatch({});
  }, [userApps.actions.initializeRequest]);

  return (
    <Fragment>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          min-height: 0;
        `}
      >
        {!isAppsLoading ? (
          <div>
            <Snackbar
              open={showToast}
              autoHideDuration={null}
              message={toastText}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              css={css`
                .MuiSnackbarContent-root {
                  text-align: center;
                  background: rgba(0, 0, 0, 0.5);
                }
                .MuiSnackbarContent-message {
                  width: 100%;
                }
              `}
            ></Snackbar>
            <div
              css={css`
                background: #f8f8f8;
              `}
            >
              {apps.map((app) => (
                <MobileAppExplorerCard
                  app={app}
                  onShare={() => onShare(app)}
                ></MobileAppExplorerCard>
              ))}
            </div>
            <Drawer
              anchor="bottom"
              open={showShareModal}
              onClose={toggleDrawer}
              css={css`
                z-index: 999;
                text-align: center;
              `}
            >
              <p
                css={css`
                  margin: 0;
                  border-bottom: 1px solid #ededed;
                  font-size: 16px;
                  line-height: 40px;
                  color: #858585;
                `}
              >
                {formatMessage(messageIds.apps.myApp.share)}
              </p>
              <div
                css={css`
                  margin: 0;
                  color: #303030;
                  line-height: 46px;
                `}
              >
                <p
                  css={css`
                    margin: 0;
                  `}
                  onClick={onCopy}
                >
                  {formatMessage(messageIds.apps.myApp.card.menu.copyAppLink)}
                </p>
                <p
                  css={css`
                    margin: 0;
                  `}
                  onClick={openScanQrCode}
                >
                  {formatMessage(messageIds.apps.myApp.card.menu.qrCodeShare)}
                </p>
                <p
                  onClick={openSendEmail}
                  css={css`
                    margin: 0;
                  `}
                >
                  {formatMessage(messageIds.apps.myApp.card.menu.emailShare)}
                </p>
              </div>
              <p
                onClick={toggleDrawer}
                css={css`
                  margin: 0;
                  border-top: 8px solid #f1f1f1;
                  line-height: 62px;
                `}
              >
                {formatMessage(messageIds.common.cancel)}
              </p>
            </Drawer>
          </div>
        ) : (
          <Spin
            css={css`
              margin: auto;
            `}
          />
        )}
      </div>
    </Fragment>
  );
});
