import { consts, LocaleLang } from "src/consts";
import { localStore } from "src/utils/storage";

export type NestedMessages = {
  [key: string]: string | NestedMessages;
};

export type NestedMessageIds<T extends NestedMessages> = {
  [P in keyof T]: T[P] extends object ? NestedMessageIds<T[P]> : string;
};

export function flattenMessages(
  nestedMessages: NestedMessages,
  prefix = ""
): Record<string, string> {
  return Object.keys(nestedMessages).reduce<Record<string, string>>(
    (messages, key) => {
      const value = nestedMessages[key];
      const prefixedKey = prefix ? `${prefix}.${key}` : key;

      if (typeof value === "string") {
        messages[prefixedKey] = value;
      } else {
        Object.assign(messages, flattenMessages(value, prefixedKey));
      }

      return messages;
    },
    {}
  );
}

export function toNestedMessageIds<TNestedMessages extends NestedMessages>(
  nestedMessages: TNestedMessages,
  prefix = ""
): NestedMessageIds<TNestedMessages> {
  return Object.keys(nestedMessages).reduce<Record<string, string | object>>(
    (nestedMessageIds, key) => {
      const value = nestedMessages[key];
      const prefixedKey = prefix ? `${prefix}.${key}` : key;

      if (typeof value === "string") {
        nestedMessageIds[key] = prefixedKey;
      } else {
        nestedMessageIds[key] = toNestedMessageIds(value, prefixedKey);
      }

      return nestedMessageIds;
    },
    {}
  ) as NestedMessageIds<TNestedMessages>;
}

export function getLocale(): LocaleLang {
  const navigatorLang = window.navigator.language.includes(
    consts.language.languagePrefixKey
  )
    ? consts.language.languageKey.SimplifiedChinese
    : consts.language.languageKey.English;
  return (
    (localStore.get(consts.language.languageCacheKey) as LocaleLang) ??
    navigatorLang
  );
}

export function setLocale(lang: string, foreReload = true) {
  if (!lang) {
    return;
  }
  localStore.set(consts.language.languageCacheKey, lang);
  if (foreReload) {
    window.location.reload();
  }
}
