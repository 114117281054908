import { createModel } from "nyax";
import { AppV2 } from "src/models/appV2";
import { AppEntityModel } from "src/store/models/entity/apps/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const AppHelperModel = createModel(
  class extends createHelperModel<AppV2>({
    setItems: (getContainer, items) =>
      getContainer(AppEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(AppEntityModel).getters.items,
    getItem: (getContainer, id) => getContainer(AppEntityModel).state.byId[id],
  }) {
    public effects() {
      return {
        ...super.effects(),
        getAll: async () => {
          return await this._readAll({
            getAllAction: () => {
              return this.dependencies.serviceClient.appV2.getAll();
            },
          });
        },
        addFavorite: async (payload: { appId: string }) => {
          const { appId } = payload;
          await this.dependencies.serviceClient.appV2.addAppFavorite(appId);
        },
        deleteFavorite: async (payload: { appId: string }) => {
          const { appId } = payload;
          await this.dependencies.serviceClient.appV2.deleteAppFavorite(appId);
        },
      };
    }
  }
);
