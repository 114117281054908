export const consts = {
  language: {
    languageCacheKey: "encoo_locale" as localStoreKey,
    languagePrefixKey: "zh",
    languageKey: {
      SimplifiedChinese: "zh-Hans" as LocaleLang,
      English: "en-US" as LocaleLang,
    },
  },
  default: {
    dateFormat: "YYYY-MM-DD HH:mm:ss",
  },
  settings: {
    OidcScope: "openid profile apigateway offline_access",
    OidcClientId: "encoo_apps",
    // OidcClientId: "encoo_console_v3",
    OidcCallbackUriAfterfix: "callback",
    OidcSilentRenewAfterfix: "silent_renew",
    OidcLogoutUriAfterfix: "index",
  },
};

export type LocaleLang = "zh-Hans" | "en-US";
export type localStoreKey = "encooSelectedUserTenant" | "encoo_locale";
export type EnvironmentEdition = "Private" | "Saas";
