import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential
} from "@encoo-web/encoo-lib";
import { AppV2 } from "src/models/appV2";
import { WxTicket } from "src/models/wxTicket";

export const APPS_V2_SERVICE_NAME = "appsV2";
export class AppsV2ServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, APPS_V2_SERVICE_NAME);
  }

  async list() {
    const req = this.buildRequest({
      url: "/v2/apps",
      method: "GET",
    });
    return await super.listInternally<AppV2>(req);
  }

  async getAll(): Promise<Array<AppV2>> {
    const req = this.buildRequest({ url: `/v2/apps`, method: "GET" });
    return await super.getAllInternal(req);
  }

  async GetTicket(companyId: string): Promise<WxTicket> {
    const req = this.buildRequest({
      url: `/v1/qywechat/ticket/corpticket?companyId=${companyId}&url=${encodeURI(
        window.location.href
      )}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async deleteAppFavorite(appId: string) {
    const req = this.buildRequest({
      url: `/v2/apps/favorites/${appId}`,
      method: "DELETE",
    });
    return await super.getAllInternal(req);
  }

  async addAppFavorite(appId: string) {
    const req = this.buildRequest({
      url: `/v2/apps/favorites/${appId}`,
      method: "POST",
    });
    return await super.getAllInternal(req);
  }

  async SendEmail(title: string, content: string, email: string) {
    const req = this.buildRequest({
      url: `/v1/emails`,
      method: "POST",
      payload: {
        title,
        content,
        recipients: [email],
      },
    });
    return await this.createInternal(req);
  }
}
